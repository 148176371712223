import React from 'react';
import {Field} from 'formik';
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import Constants from "../../../../utils/constants";


function PrestationDataField({...props}) {
    const {values, errors, handleChange, handleBlur} = props;


    return (
        <Grid container spacing={1} className={"sectionContainer"}>
            <Grid item xs={12}>
                <Typography variant={"h5"} className={"sectionHeader"}>Informations</Typography>
            </Grid>

            {/*LIBELLE FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Libellé <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        value={values.libelle}>
                        {({field, form}) => (
                            <TextField
                                error={
                                    Boolean(form.errors.libelle && form.touched.libelle)
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.libelle}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                fullWidth
                                name="libelle"
                                label="Libellé"
                                autoFocus
                                helperText={
                                    form.errors.libelle &&
                                    form.touched.libelle &&
                                    String(form.errors.libelle)
                                }/>
                        )}
                    </Field>
                </Grid>
            </Grid>

        </Grid>
    );
}

export default PrestationDataField;
