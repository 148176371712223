import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import Constants from "../../../utils/constants";
import './PatrimoineMap.css'
import {helper} from "../../../utils/helper";
import {Icon, divIcon} from 'leaflet';
import CustomTileLayer from "../layout/map/CustomTileLayer.js";
import FullscreenControl from "../map/FullscreenControl.js";
import ConteneurMarkerPopup from "../map/ConteneurMarkerPopup.js";
import {Grid, Paper, TextField} from "@mui/material";
import Button from "@mui/material/Button";

function PatrimoineMap({patrimoine, conteneurs, onConteneurClick, onConteneurUpdate, ...props}) {
    const [conteneursData, setConteneurData] = useState(null)
    const [expandedMarkers, setExpandedMarkers] = useState({});
    const [selectedConteneur, setSelectedConteneur] = useState(null);
    const [mapCenter, setMapCenter] = useState(null);
    const [mapZoom, setMapZoom] = useState(13);
    const mapRef = useRef(null);
    const [editingConteneur, setEditingConteneur] = useState(null);
    const [editingPosition, setEditingPosition] = useState(null);


    const calculateCenter = useCallback((conteneurs) => {
        const totalLat = conteneurs.reduce((acc, c) => acc + parseFloat(c.latitude), 0);
        const totalLng = conteneurs.reduce((acc, c) => acc + parseFloat(c.longitude), 0);
        const avgLat = totalLat / conteneurs.length;
        const avgLng = totalLng / conteneurs.length;
        return [avgLat, avgLng];
    }, []);

    useEffect(() => {
        if (conteneurs && conteneurs.length > 0) {
            const newCenter = calculateCenter(conteneurs);
            setMapCenter(newCenter);
        } else if (patrimoine.isUnipatrimoine) {
            setMapCenter([patrimoine.latitude, patrimoine.longitude]);
        }
    }, [conteneurs, patrimoine, calculateCenter]);

    useEffect(() => {
        if (conteneurs) {
            const data = conteneurs.reduce((acc, conteneur) => {
                const key = `${conteneur.latitude}/${conteneur.longitude}`;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(conteneur);
                return acc;
            }, {});
            setConteneurData(data);
        }
    }, [conteneurs]);

    useEffect(() => {
        if (mapRef.current && mapCenter) {
            mapRef.current.setView(mapCenter, mapZoom);
        }
    }, [mapCenter, mapZoom]);

    const getAverageConteneurStatusIcon = useMemo(() => (conteneurs) => {
        if (!conteneurs.length) return helper.getConteneurStatusIcon(1);
        const highestStatus = Math.max(...conteneurs.map(c => c.status || 0));
        return helper.getConteneurStatusIcon(highestStatus);
    }, []);

    const getConteneurStatusIcon = (c) => {
        return helper.getConteneurStatusIcon(c.status)
    }

    function getIcon(c, count) {
        let imgSrc = getAverageConteneurStatusIcon(c);
        return divIcon({
            className: 'my-div-icon',
            html: `<img class="marker-image" src="${imgSrc}"/>` +
                `<span class="marker-number-div">${count}</span>`,
            iconSize: [(56 / 1.5), (76 / 1.5)]
        });
    }

    const MapController = () => {
        const map = useMapEvents({
            click: (e) => {
                if (editingConteneur) {
                    setEditingPosition([e.latlng.lat, e.latlng.lng]);
                }
            },
        });

        useEffect(() => {
            if (!mapRef.current) {
                mapRef.current = map;
            }
        }, [map]);

        return null;
    };

    const onMarkerClick = useCallback((c) => {
        const key = `${c.latitude}/${c.longitude}`;
        const numMarkers = conteneursData[key]?.length || 0;

        if (numMarkers > 1) {
            setExpandedMarkers(prevExpanded => ({
                ...prevExpanded,
                [key]: !prevExpanded[key]
            }));
        }

        setSelectedConteneur(c);
        //onConteneurClick(conteneursData[key]);
    }, [conteneursData, onConteneurClick]);

    const handleClosePopup = useCallback(() => {
        setSelectedConteneur(null);
    }, []);

    const handleOnEdit = useCallback((conteneur) => {
        setEditingConteneur(conteneur);
        setEditingPosition([conteneur.latitude, conteneur.longitude]);
    }, []);

    const handleDragEnd = useCallback((event) => {
        if (editingConteneur) {
            const newPosition = event.target.getLatLng();
            setEditingPosition([newPosition.lat, newPosition.lng]);
        }
    }, [editingConteneur]);

    const handleSaveEdit = useCallback(() => {
        if (editingConteneur && editingPosition) {
            editingConteneur.latitude = editingPosition[0];
            editingConteneur.longitude = editingPosition[1];

            onConteneurUpdate(editingConteneur)


            //reset
            setEditingConteneur(null);
            setEditingPosition(null);
        }
    }, [editingConteneur, editingPosition]);

    const handleCancelEdit = useCallback(() => {
        setEditingConteneur(null);
        setEditingPosition(null);
    }, []);


    return (
        <MapContainer
            ref={mapRef}
            center={mapCenter}
            zoom={mapZoom}
            whenCreated={(map) => {
                mapRef.current = map;
            }}
            style={{height: "85vh", width: "100%"}}>
            <MapController/>
            <CustomTileLayer
                maxZoom={19}
            />

            {conteneursData && Object.entries(conteneursData).map(([key, markers]) => (
                <MarkerGroup
                    key={key}
                    markers={markers}
                    isExpanded={expandedMarkers[key]}
                    getIcon={getIcon}
                    onMarkerClick={onMarkerClick}
                    handleClosePopup={handleClosePopup}
                    handleOnEdit={handleOnEdit}
                    editingConteneur={editingConteneur}
                    editingPosition={editingPosition}
                    handleDragEnd={handleDragEnd}
                />
            ))}
            {patrimoine.isUnipatrimoine &&
                <Marker
                    icon={new Icon({
                        iconUrl: getConteneurStatusIcon(patrimoine),
                        iconSize: [(56 / 1.5), (76 / 1.5)],
                    })}
                    position={[patrimoine.latitude, patrimoine.longitude]}/>
            }
            <FullscreenControl/>
            {editingConteneur && (
                <Popup
                    position={editingPosition}
                    onClose={handleCancelEdit}
                >
                    <Paper className={"marker-popup-container"}
                           sx={{background: 'white', padding: '1rem', width: 200}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={handleSaveEdit}
                                >
                                    Sauvegarder
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="error"
                                    fullWidth
                                    onClick={handleCancelEdit}
                                >
                                    Annuler
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Popup>
            )}
        </MapContainer>
    )
}

const MarkerGroup = React.memo(({
                                    markers,
                                    isExpanded,
                                    getIcon,
                                    onMarkerClick,
                                    handleClosePopup,
                                    handleOnEdit,
                                    editingConteneur,
                                    editingPosition,
                                    handleDragEnd
                                }) => {
    const baseMarker = markers[0];
    const baseLat = parseFloat(baseMarker.latitude);
    const baseLng = parseFloat(baseMarker.longitude);

    return markers.map((marker, index) => {
        let lat = baseLat;
        let lng = baseLng;

        if (isExpanded && markers.length > 1) {
            const angle = (2 * Math.PI * index) / markers.length;
            lat = baseLat + Constants.MAP_RADIUS * Math.cos(angle);
            lng = baseLng + (Constants.MAP_RADIUS * Math.sin(angle)) / Math.cos(baseLat / Constants.DEGREES_PER_RADIAN);
        }

        const isEditing = editingConteneur && editingConteneur.id === marker.id;
        const markerPosition = isEditing ? editingPosition : [lat, lng];

        return (
            <Marker
                key={`${marker.id}-${lat}-${lng}`}
                position={markerPosition}
                icon={getIcon(markers, markers[0]?.quantite)}
                eventHandlers={{
                    click: () => onMarkerClick(marker),
                    dragend: handleDragEnd,
                }}
                draggable={isEditing}
            >
                {!isEditing && (
                    <ConteneurMarkerPopup
                        key={`${marker.id}-${lat}-${lng}-${index}`}
                        conteneur={marker}
                        onClose={handleClosePopup}
                        onEdit={() => handleOnEdit(marker)}
                    />
                )}
            </Marker>
        );
    });
});

export default React.memo(PatrimoineMap);
