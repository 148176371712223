import {
    deleteHeaders,
    fetchHeaders,
    patchHeaders,
    postHeaders,
} from "../../utils/headers";
import Constants from "../../utils/constants";

export const prestationsService = {
    fetchPrestations,
    fetchPrestation,
    deletePrestation,
    createPrestation,
    updatePrestation,
};

function fetchPrestations() {
    const requestOptions = fetchHeaders()

    return fetch(Constants.API_ROUTE_PRESTATIONS, requestOptions)
        .then(handleResponse)
        .then(prestations => {
            return prestations["hydra:member"]
        });
}

function fetchPrestation(id) {
    const requestOptions = fetchHeaders()

    let url = Constants.API_ROUTE_PRESTATION.replace(':id', id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(prestation => {
            return prestation
        });
}

function createPrestation(values) {
    const requestOptions = postHeaders(values)

    let url = Constants.API_ROUTE_PRESTATIONS;

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function updatePrestation(values) {
    const requestOptions = patchHeaders(values)
    let url = Constants.API_ROUTE_PRESTATION.replace(':id', values.id);

    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return "ok"
        });
}

function deletePrestation(id) {
    const requestOptions = deleteHeaders()

    let url = Constants.API_ROUTE_PRESTATION.replace(':id', id);
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(() => {
            return id
        });
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {

            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}
