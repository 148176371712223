import {conteneursConstants} from "./conteneurs.constants";
import {conteneursService} from "./conteneurs.service";
import {toastHelper} from "../../utils/toast-helper";
import Constants from "../../utils/constants";
import history from "../../utils/history";

export const conteneursActions = {
    fetchConteneurs,
    fetchConteneursForPatrimoine,
    fetchConteneur,
    deleteConteneur,
    createConteneur,
    updateConteneur,
    clearConteneurs
};

function fetchConteneurs() {
    return dispatch => {
        dispatch(request());

        conteneursService.fetchConteneurs()
            .then(
                conteneurs => {
                    dispatch(success(conteneurs));
                },
                error => {

                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: conteneursConstants.CONTENEURS_FETCH_REQUEST}
    }

    function success(conteneurs) {
        return {type: conteneursConstants.CONTENEURS_FETCH_SUCCESS, conteneurs}
    }

    function failure(error) {
        return {type: conteneursConstants.CONTENEURS_FETCH_FAILURE, error}
    }
}

function fetchConteneursForPatrimoine() {
    return dispatch => {
        dispatch(request());

        conteneursService.fetchConteneurs()
            .then(
                conteneurs => {
                    dispatch(success(conteneurs));
                },
                error => {

                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: conteneursConstants.CONTENEURS_FETCH_REQUEST}
    }

    function success(conteneurs) {
        return {type: conteneursConstants.CONTENEURS_FETCH_SUCCESS, conteneurs}
    }

    function failure(error) {
        return {type: conteneursConstants.CONTENEURS_FETCH_FAILURE, error}
    }
}

function fetchConteneur(id) {
    return dispatch => {
        dispatch(request());
        conteneursService.fetchConteneur(id)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    if (!error || error === "") error = "Une erreur est survenue"
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: conteneursConstants.CONTENEURS_FETCH_ONE_REQUEST}
    }

    function success(conteneur) {
        return {type: conteneursConstants.CONTENEURS_FETCH_ONE_SUCCESS, conteneur}
    }

    function failure(error) {
        return {type: conteneursConstants.CONTENEURS_FETCH_ONE_FAILURE, error}
    }
}

function deleteConteneur(id, navigate) {
    return dispatch => {
        dispatch(request());

        conteneursService.deleteConteneur(id)
            .then(
                id => {
                    dispatch(success(id));
                    history.replace(Constants.APP_ROUTE_ADMIN_CONTENEURS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };


    function request() {
        return {type: conteneursConstants.CONTENEURS_DELETE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Le conteneur a bien été supprimé.")
        return {type: conteneursConstants.CONTENEURS_DELETE_SUCCESS, id}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: conteneursConstants.CONTENEURS_DELETE_FAILURE, error}
    }
}

function createConteneur(values, navigate) {
    return dispatch => {
        dispatch(request());

        conteneursService.createConteneur(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_CONTENEURS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: conteneursConstants.CONTENEURS_CREATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Le conteneur a bien été créé.")
        return {type: conteneursConstants.CONTENEURS_CREATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: conteneursConstants.CONTENEURS_CREATE_FAILURE, error}
    }
}

function updateConteneur(values, navigate) {
    return dispatch => {
        dispatch(request());
        conteneursService.updateConteneur(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_CONTENEURS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: conteneursConstants.CONTENEURS_UPDATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("Le conteneur a bien été modifié.")
        return {type: conteneursConstants.CONTENEURS_UPDATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: conteneursConstants.CONTENEURS_UPDATE_FAILURE, error}
    }
}

function clearConteneurs() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {type: conteneursConstants.CONTENEURS_CLEAR_REQUEST}
    }
}
