import {prestationsConstants} from './prestations.constants.js';
import {authenticationConstants} from "../authentication/auth.constants";

const initialState = {prestations: null, prestation: null, loading: false, error: null}

export function prestations(state = initialState, action) {
    switch (action.type) {
        case prestationsConstants.PRESTATIONS_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case prestationsConstants.PRESTATIONS_FETCH_SUCCESS:
            return {
                ...state,
                prestations: action.prestations,
                prestation: null,
                loading: false,
                error: null
            };
        case prestationsConstants.PRESTATIONS_FETCH_ONE_REQUEST:
            return {
                ...state,
                prestation: null,
                loading: true,
                error: null
            };
        case prestationsConstants.PRESTATIONS_FETCH_ONE_SUCCESS:
            return {
                ...state,
                prestation: action.prestation,
                prestations: null,
                loading: false,
                error: null
            };
        case prestationsConstants.PRESTATIONS_FETCH_ONE_FAILURE:
            return {
                ...state,
                prestation: null,
                loading: false,
                error: action.error
            };
        case prestationsConstants.PRESTATIONS_DELETE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null
            };
        case prestationsConstants.PRESTATIONS_DELETE_SUCCESS:
        case prestationsConstants.PRESTATIONS_CREATE_SUCCESS:
        case prestationsConstants.PRESTATIONS_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                prestations: null,
                prestation: null
            };
        case prestationsConstants.PRESTATIONS_FETCH_FAILURE:
        case prestationsConstants.PRESTATIONS_CREATE_FAILURE:
        case prestationsConstants.PRESTATIONS_UPDATE_FAILURE:
        case prestationsConstants.PRESTATIONS_DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case prestationsConstants.PRESTATIONS_CLEAR_REQUEST:
            return {
               ...initialState
            }
        case authenticationConstants.LOGOUT:
            return {
                initialState
            }
        default:
            return state
    }
}
