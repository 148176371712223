import {applyMiddleware, combineReducers, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';
import {jwtMiddleware} from '../modules/jwt-middleware';
import {authentication} from "../modules/authentication/auth.reducer";
import {users} from "../modules/users/users.reducer";
import {patrimoines} from "../modules/patrimoines/patrimoines.reducer";
import {problems} from "../modules/problems/problems.reducer";
import {forms} from "../modules/forms/forms.reducer";
import {laveuses} from "../modules/laveuses/laveuses.reducer";
import {campaigns} from "../modules/campaigns/campaigns.reducer";
import {interventions} from "../modules/interventions/interventions.reducer";
import {conteneurs} from "../modules/conteneurs/conteneurs.reducer";
import {dashboard} from "../modules/dashboard/dashboard.reducer";
import {prestations} from "../modules/prestations/prestations.reducer";

const loggerMiddleware = createLogger();

const store = createStore(
    combineReducers({
        authentication,
        users,
        patrimoines,
        problems,
        forms,
        laveuses,
        campaigns,
        interventions,
        conteneurs,
        dashboard,
        prestations
    }),
    applyMiddleware(
        jwtMiddleware,
        thunkMiddleware,
        loggerMiddleware
    )
);

export default store;
