import React from 'react';
import {Field} from 'formik';
import {
    Button,
    FormControl,
    FormHelperText,
    Grid, IconButton,
    InputLabel,
    MenuItem,
    Select, Slider,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import {PatchMinus} from "@styled-icons/bootstrap";
import {Add, AddBoxRounded, AddCircle, Remove, RemoveCircle} from "@mui/icons-material";
import {faSquareMinus, faSquarePlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Constants from "../../../../utils/constants.js";
import {helper} from "../../../../utils/helper.js";


function FormDataField({...props}) {
    const {clients, values, errors, handleChange, handleBlur} = props;

    const photoNumberSliderMarks = [{value: 0, label: "Facultative"}, {value: 1, label: "1"}, {
        value: 2,
        label: "2"
    }, {value: 3, label: "3"}, {value: 4, label: "4"}, {value: 5, label: "5"}]

    return (
        <Grid container spacing={1} className={"sectionContainer"}>
            <Grid item xs={12}>
                <Typography variant={"h5"} className={"sectionHeader"}>Informations</Typography>
            </Grid>

            {/*CLIENT FIELD*/}
            <Grid item container alignItems={"center"} className={"mt-2"}>
                <Grid item xs={3}>
                    Client à rattacher <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        name="client"
                        value={values.client}>
                        {({field, form}) => (
                            <FormControl fullWidth error={Boolean(errors.client)}>
                                <InputLabel id="label-client">Sélectionner le client :</InputLabel>
                                <Select
                                    labelId="label-client"
                                    label="Sélectionner l'client :"
                                    variant="outlined"
                                    name="client"
                                    value={values.client}
                                    onChange={handleChange}
                                >
                                    {clients && clients.map((e) => {
                                        return (
                                            <MenuItem key={e.id} value={e["@id"]}>
                                                {e.libelle}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                {form.errors.client &&
                                    form.touched.client &&
                                    String(form.errors.client) &&
                                    <FormHelperText error={true}>{form.errors.client}</FormHelperText>}
                            </FormControl>
                        )
                        }
                    </Field>
                </Grid>
            </Grid>

            {/*LIBELLE FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Libellé <span className="asterix">*</span>
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        value={values.libelle}>
                        {({field, form}) => (
                            <TextField
                                error={
                                    Boolean(form.errors.libelle && form.touched.libelle)
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.libelle}
                                variant="outlined"
                                margin="normal"
                                size="small"
                                fullWidth
                                name="libelle"
                                label="Libellé"
                                autoFocus
                                helperText={
                                    form.errors.libelle &&
                                    form.touched.libelle &&
                                    String(form.errors.libelle)
                                }/>
                        )}
                    </Field>
                </Grid>
            </Grid>

            {/*TYPE CONTENEUR FIELD*/}
            <Grid item container alignItems={"center"} className={"mt-1"}>
                <Grid item xs={3}>
                    Type de conteneur
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        value={values.type ? values.type : false}>
                        {({field, form}) => (
                            <ToggleButtonGroup
                                color={"primary"}
                                exclusive
                                size={"medium"}
                                name="type"
                                value={values.type}
                                onChange={(event, newValue) => {
                                    if (newValue !== null) form.setFieldValue('type', newValue);
                                }}
                                aria-label={"type"}
                            >

                                <ToggleButton value={Constants.TYPOLOGIE_LAVAGE_AB_PLUS_B}
                                              className={values.type === Constants.TYPOLOGIE_LAVAGE_AB_PLUS_B ? "toggle-button button-selected-primary" : "toggle-button"}>
                                    {Constants.TYPOLOGIE_LAVAGE_AB_PLUS_B}
                                </ToggleButton>
                                <ToggleButton value={Constants.TYPOLOGIE_LAVAGE_AB}
                                              className={values.type === Constants.TYPOLOGIE_LAVAGE_AB ? "toggle-button button-selected-secondary" : "toggle-button"}>
                                    {Constants.TYPOLOGIE_LAVAGE_AB}
                                </ToggleButton>
                                <ToggleButton value={Constants.TYPOLOGIE_LAVAGE_B}
                                              className={values.type === Constants.TYPOLOGIE_LAVAGE_B ? "toggle-button button-selected-orange" : "toggle-button"}>
                                    {Constants.TYPOLOGIE_LAVAGE_B}
                                </ToggleButton>

                                <ToggleButton value={Constants.TYPOLOGIE_LAVAGE_C}
                                              className={values.type === Constants.TYPOLOGIE_LAVAGE_C ? "toggle-button button-selected-gray" : "toggle-button"}>
                                    {Constants.TYPOLOGIE_LAVAGE_C}
                                </ToggleButton>
                            </ToggleButtonGroup>

                        )}
                    </Field>

                </Grid>
            </Grid>

            {/*PHOTO AVANT OPTIONAL FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Nombre de photo <strong>AVANT</strong>
                </Grid>
                <Grid item xs={3}>
                    <Field
                        type="number"
                        name={"minPhotoAvant"}
                        value={values.minPhotoAvant !== null ? values.minPhotoAvant : 1}>
                        {({field, form}) => (
                            <Slider
                                aria-label="Nombre minimum de photo avant"
                                value={values.minPhotoAvant !== null ? values.minPhotoAvant : 1}
                                min={0}
                                max={5}
                                onChange={(e, value) => {
                                    form.setFieldValue("minPhotoAvant", value)
                                }}
                                valueLabelDisplay="auto"
                                marks={photoNumberSliderMarks}
                            />
                        )}
                    </Field>

                </Grid>
            </Grid>

            {/*NOMBRE PHOTO APRES FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Nombre de photo <strong>APRES</strong>
                </Grid>
                <Grid item xs={3}>
                    <Field
                        type="number"
                        name={"minPhotoApres"}
                        value={values.minPhotoApres !== null ? values.minPhotoApres : 1}>
                        {({field, form}) => (
                            <Slider
                                aria-label="Nombre minimum de photo apres"
                                value={values.minPhotoApres !== null ? values.minPhotoApres : 1}
                                min={0}
                                max={5}
                                onChange={(e, value) => {
                                    form.setFieldValue("minPhotoApres", value)
                                }}
                                valueLabelDisplay="auto"
                                marks={photoNumberSliderMarks}
                            />
                        )}
                    </Field>
                </Grid>
            </Grid>

            {/*NOMBRE PHOTO MAINTENANCE FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Nombre de photo <strong>MAINTENANCE</strong>
                </Grid>
                <Grid item xs={3}>
                    <Field
                        type="number"
                        value={values.minPhotoMaintenance !== null ? values.minPhotoMaintenance : 0}>
                        {({field, form}) => (
                            <Slider
                                aria-label="Nombre minimum de photo maintenance"
                                value={values.minPhotoMaintenance !== null ? values.minPhotoMaintenance : 0}
                                min={0}
                                max={5}
                                onChange={(e, value) => {
                                    form.setFieldValue("minPhotoMaintenance", value)
                                }}
                                valueLabelDisplay="auto"
                                marks={photoNumberSliderMarks}
                            />
                        )}
                    </Field>
                </Grid>
            </Grid>

            {/*NOMBRE PHOTO IMPOSSIBLE FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Nombre de photo <strong>LAVAGE IMPOSSIBLE</strong>
                </Grid>
                <Grid item xs={3}>
                    <Field
                        type="number"
                        value={values.minPhotoLavageImpossible !== null ? values.minPhotoLavageImpossible : 0}>
                        {({field, form}) => (
                            <Slider
                                aria-label="Nombre minimum de photo lavage impossible"
                                value={values.minPhotoLavageImpossible !== null ? values.minPhotoLavageImpossible : 0}
                                min={0}
                                max={5}
                                onChange={(e, value) => {
                                    form.setFieldValue("minPhotoLavageImpossible", value)
                                }}
                                valueLabelDisplay="auto"
                                marks={photoNumberSliderMarks}
                            />
                        )}
                    </Field>
                </Grid>
            </Grid>

            {/*GALLERY ENABLE FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Activer La gallerie
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        value={values.galleryEnabled ? values.galleryEnabled : false}>
                        {({field, form}) => (
                            <ToggleButtonGroup
                                color={"primary"}
                                exclusive
                                size={"medium"}
                                name="galleryEnabled"
                                value={values.galleryEnabled}
                                onChange={(event, newValue) => {
                                    if (newValue !== null) form.setFieldValue('galleryEnabled', newValue);
                                }}
                                aria-label={"Qrcode"}
                            >
                                <ToggleButton value={false}
                                              className={values.galleryEnabled === false ? "toggle-button button-selected-red" : "toggle-button"}>Non</ToggleButton>
                                <ToggleButton value={true}
                                              className={values.galleryEnabled === true ? "toggle-button button-selected-green" : "toggle-button"}>Oui</ToggleButton>
                            </ToggleButtonGroup>

                        )}
                    </Field>

                </Grid>
            </Grid>

            {/*QRCODE FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Activer QRCode
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        value={values.hasQrcode ? values.hasQrcode : false}>
                        {({field, form}) => (
                            <ToggleButtonGroup
                                color={"primary"}
                                exclusive
                                size={"medium"}
                                name="hasQrcode"
                                value={values.hasQrcode}
                                onChange={(event, newValue) => {
                                    if (newValue !== null) form.setFieldValue('hasQrcode', newValue);
                                }}
                                aria-label={"Qrcode"}
                            >
                                <ToggleButton value={false}
                                              className={values.hasQrcode === false ? "toggle-button button-selected-red" : "toggle-button"}>Non</ToggleButton>
                                <ToggleButton value={true}
                                              className={values.hasQrcode === true ? "toggle-button button-selected-green" : "toggle-button"}>Oui</ToggleButton>
                            </ToggleButtonGroup>

                        )}
                    </Field>

                </Grid>
            </Grid>

            {/*BARCODE FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Activer Code Barre
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        value={values.hasBarcode ? values.hasBarcode : false}>
                        {({field, form}) => (
                            <ToggleButtonGroup
                                color={"primary"}
                                exclusive
                                size={"medium"}
                                name="hasBarcode"
                                value={values.hasBarcode}
                                onChange={(event, newValue) => {
                                    if (newValue !== null) form.setFieldValue('hasBarcode', newValue);
                                }}
                                aria-label={"Barcode"}
                            >
                                <ToggleButton value={false}
                                              className={values.hasBarcode === false ? "toggle-button button-selected-red" : "toggle-button"}>Non</ToggleButton>
                                <ToggleButton value={true}
                                              className={values.hasBarcode === true ? "toggle-button button-selected-green" : "toggle-button"}>Oui</ToggleButton>
                            </ToggleButtonGroup>

                        )}
                    </Field>

                </Grid>
            </Grid>

            {/*RFID FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Activer RFID
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        value={values.hasRFID ? values.hasRFID : false}>
                        {({field, form}) => (
                            <ToggleButtonGroup
                                color={"primary"}
                                exclusive
                                size={"medium"}
                                name="hasRFID"
                                value={values.hasRFID}
                                onChange={(event, newValue) => {
                                    if (newValue !== null) form.setFieldValue('hasRFID', newValue);
                                }}
                                aria-label={"hasRFID"}
                            >
                                <ToggleButton value={false}
                                              className={values.hasRFID === false ? "toggle-button button-selected-red" : "toggle-button"}>Non</ToggleButton>
                                <ToggleButton value={true}
                                              className={values.hasRFID === true ? "toggle-button button-selected-green" : "toggle-button"}>Oui</ToggleButton>
                            </ToggleButtonGroup>

                        )}
                    </Field>

                </Grid>
            </Grid>

            {/*HAS VOLUME FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Activer Volume
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        value={values.hasVolume ? values.hasVolume : false}>
                        {({field, form}) => (
                            <ToggleButtonGroup
                                color={"primary"}
                                exclusive
                                size={"medium"}
                                name="hasVolume"
                                value={values.hasVolume}
                                onChange={(event, newValue) => {
                                    if (newValue !== null) form.setFieldValue('hasVolume', newValue);
                                }}
                                aria-label={"Qrcode"}
                            >
                                <ToggleButton value={false}
                                              className={values.hasVolume === false ? "toggle-button button-selected-red" : "toggle-button"}>Non</ToggleButton>
                                <ToggleButton value={true}
                                              className={values.hasVolume === true ? "toggle-button button-selected-green" : "toggle-button"}>Oui</ToggleButton>
                            </ToggleButtonGroup>

                        )}
                    </Field>

                </Grid>
            </Grid>

            {/*HAS WASTE FIELD*/}
            <Grid item container alignItems={"center"}>
                <Grid item xs={3}>
                    Activer Type de déchet
                </Grid>
                <Grid item xs={9}>
                    <Field
                        type="text"
                        value={values.hasWaste ? values.hasWaste : false}>
                        {({field, form}) => (
                            <ToggleButtonGroup
                                color={"primary"}
                                exclusive
                                size={"medium"}
                                name="hasWaste"
                                value={values.hasWaste}
                                onChange={(event, newValue) => {
                                    if (newValue !== null) form.setFieldValue('hasWaste', newValue);
                                }}
                                aria-label={"Qrcode"}
                            >
                                <ToggleButton value={false}
                                              className={values.hasWaste === false ? "toggle-button button-selected-red" : "toggle-button"}>Non</ToggleButton>
                                <ToggleButton value={true}
                                              className={values.hasWaste === true ? "toggle-button button-selected-green" : "toggle-button"}>Oui</ToggleButton>
                            </ToggleButtonGroup>

                        )}
                    </Field>

                </Grid>
            </Grid>
        </Grid>
    );
}

export default FormDataField;
