import {
    FormControl, FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import React from "react";
import {Field} from "formik";
import {RRule, RRuleSet, rrulestr} from 'rrule';
import {helper} from "../../../utils/helper";
import {DatePicker} from "@mui/x-date-pickers";
import {format, parseISO} from "date-fns";
import Constants from "../../../utils/constants";

function SyntheseFilter({isAdmin, isExploitant, exploitants, clients, values, errors, handleChange, onPatrimoineSelected, patrimoine, ...props}) {

    const selectedExploitant = () => {
        return exploitants?.find((e) => e.id === values.exploitant)
    }

    const selectedClient = () => {
        let selectedClient
        if (clients) {
            selectedClient = clients?.find((c) => c.id === values.client)
        } else {
            selectedClient = selectedExploitant()?.clients?.find((c) => c.id === values.client)
        }
        return selectedClient;
    }

    const selectedPatrimoine = () => {
        onPatrimoineSelected(values.patrimoine);
    }

    const filteredClients = () => {
        if (values.exploitant) {
            if (isAdmin) return selectedExploitant()?.clients;
            if (isExploitant) return clients;
        }
        return []
    }

    const filteredPatrimoines = () => {
        if (values.client) {
            return selectedClient()?.patrimoines.sort((a, b) => a.libelle.localeCompare(b.libelle))
        }
        return []
    }


    const sortedConteneurs = () => {
        if (!patrimoine || !patrimoine.conteneurs) {
            return []; // Si le patrimoine ou les conteneurs ne sont pas chargés, retourne un tableau vide
        }

        try {
            return patrimoine.conteneurs.sort((a, b) => {
                return a.nomenclature.localeCompare(b.nomenclature); // Tri par nomenclature
            });
        } catch (e) {
            console.warn("Error sorting conteneurs: ", e);
        }

        return [];
    };


    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <Grid container>

                        {/*{EXPLOITANT FIELD}*/}
                        {isAdmin &&
                            <Grid item container alignItems={"center"} className={"mt-2"}>
                                <Grid item xs={3}>
                                    Sélection de l'exploitant <span className="asterix">*</span>
                                </Grid>
                                <Grid item xs={9}>
                                    <Field
                                        type="text"
                                        name="exploitant"
                                        value={values.exploitant}>
                                        {({field, form}) => (
                                            <FormControl fullWidth error={Boolean(errors.exploitant)}>
                                                <InputLabel id="label-exploitant">Sélectionner l'exploitant
                                                    :</InputLabel>
                                                <Select
                                                    labelId="label-exploitant"
                                                    label="Sélectionner l'exploitant :"
                                                    variant="outlined"
                                                    name="exploitant"
                                                    value={values.exploitant}
                                                    onChange={handleChange}
                                                >
                                                    {exploitants && exploitants.map((e) => {
                                                        return (
                                                            <MenuItem key={e['@id']} value={e.id}>
                                                                {e.libelle}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                                {form.errors.exploitant &&
                                                    form.touched.exploitant &&
                                                    String(form.errors.exploitant) &&
                                                    <FormHelperText
                                                        error={true}>{form.errors.exploitant}</FormHelperText>}
                                            </FormControl>
                                        )
                                        }
                                    </Field>
                                </Grid>
                            </Grid>
                        }

                        {/*{CLIENT FIELD}*/}
                        {((isAdmin || isExploitant) && values.exploitant) &&
                            <Grid item container alignItems={"center"} className={"mt-2"}>
                                <Grid item xs={3}>
                                    Sélection du client <span className="asterix">*</span>
                                </Grid>
                                <Grid item xs={9}>
                                    <Field
                                        type="text"
                                        name="client"
                                        value={values.client}>
                                        {({field, form}) => (
                                            <FormControl fullWidth error={Boolean(errors.client)}>
                                                <InputLabel id="label-client">
                                                    Sélectionner le client :
                                                </InputLabel>
                                                <Select
                                                    labelId="label-client"
                                                    label="Sélectionner le client :"
                                                    variant="outlined"
                                                    name="client"
                                                    value={values.client}
                                                    onChange={handleChange}
                                                >
                                                    {filteredClients() && filteredClients().map((c) => {
                                                        return (
                                                            <MenuItem key={c['@id']} value={c.id}>
                                                                {c.libelle}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                                {form.errors.client &&
                                                    form.touched.client &&
                                                    String(form.errors.client) &&
                                                    <FormHelperText
                                                        error={true}>{form.errors.client}</FormHelperText>}
                                            </FormControl>
                                        )
                                        }
                                    </Field>
                                </Grid>
                            </Grid>
                        }

                        {/*BILAN TYPE FIELD*/}
                        <Grid item container alignItems={"center"} className={"mt-2"}>
                            <Grid item xs={3}>
                                <Typography style={{color: patrimoine?.isUnipatrimoine ? 'grey' : 'inherit'}}>
                                    Type de bilan
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    type="text"
                                    value={values.byPAV ? values.byPAV : false}>
                                    {({field, form}) => (
                                        <ToggleButtonGroup
                                            color={"primary"}
                                            exclusive
                                            size={"medium"}
                                            name="bilanType"
                                            value={values.byPAV}
                                            onChange={(event, newValue) => {
                                                if (newValue !== null) form.setFieldValue('bilanType', newValue);
                                            }}
                                            aria-label={"Type de bilan"}
                                        >
                                            <ToggleButton value={Constants.BILAN_TYPE_GLOBAL}
                                                          className={values.bilanType === Constants.BILAN_TYPE_GLOBAL ? "toggle-button button-selected-green" : "toggle-button"}>Global (CSV)</ToggleButton>
                                            <ToggleButton value={Constants.BILAN_TYPE_BY_PATRIMOINE}
                                                          className={values.bilanType === Constants.BILAN_TYPE_BY_PATRIMOINE ? "toggle-button button-selected-primary" : "toggle-button"}>Par patrimoine</ToggleButton>
                                        </ToggleButtonGroup>

                                    )}
                                </Field>
                            </Grid>
                            <Grid item xs={7}>
                                {patrimoine?.isUnipatrimoine &&
                                    <Typography
                                        style={{color: patrimoine?.isUnipatrimoine ? 'grey' : 'inherit'}}>
                                        Impossible de sélectionner par PAV pour un unipatrimoine
                                    </Typography>
                                }
                            </Grid>
                        </Grid>


                        {values.bilanType === Constants.BILAN_TYPE_BY_PATRIMOINE &&
                            <>
                                {/* PATRIMOINES FIELD */}
                                <Grid item container alignItems={"center"} className={"mt-2"}>
                                    <Grid item xs={3}>
                                        Sélection du patrimoine <span className="asterix">*</span>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Field
                                            type="text"
                                            name="patrimoine"
                                            value={values.patrimoine}>
                                            {({field, form}) => (
                                                <FormControl fullWidth error={Boolean(errors.patrimoine)}>
                                                    <InputLabel id="label-patrimoine">
                                                        Sélectionner le patrimoine :
                                                    </InputLabel>
                                                    <Select
                                                        labelId="label-patrimoine"
                                                        label="Sélectionner le patrimoine :"
                                                        variant="outlined"
                                                        name="patrimoine"
                                                        value={values.patrimoine}
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            onPatrimoineSelected(event.target.value);
                                                        }}
                                                    >
                                                        {filteredPatrimoines() && filteredPatrimoines().map((p) => {
                                                            return (
                                                                <MenuItem key={p['@id']} value={p.id}>
                                                                    {p.libelle}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                    {form.errors.patrimoine &&
                                                        form.touched.patrimoine &&
                                                        String(form.errors.patrimoine) &&
                                                        <FormHelperText
                                                            error={true}>{form.errors.patrimoine}</FormHelperText>}
                                                </FormControl>
                                            )
                                            }
                                        </Field>
                                    </Grid>
                                </Grid>

                                {/*By PAV FIELD*/}
                                <Grid item container alignItems={"center"} className={"mt-2"}>
                                    <Grid item xs={3}>
                                        <Typography
                                            style={{color: patrimoine?.isUnipatrimoine ? 'grey' : 'inherit'}}>
                                            Par PAV ?
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Field
                                            type="text"
                                            value={values.byPAV ? values.byPAV : false}>
                                            {({field, form}) => (
                                                <ToggleButtonGroup
                                                    color={"primary"}
                                                    exclusive
                                                    size={"medium"}
                                                    name="byPAV"
                                                    value={values.byPAV}
                                                    onChange={(event, newValue) => {
                                                        if (patrimoine.isUnipatrimoine) return;
                                                        if (newValue !== null) form.setFieldValue('byPAV', newValue);
                                                    }}
                                                    aria-label={"Par PAV"}
                                                >
                                                    <ToggleButton value={true}
                                                                  disabled={patrimoine?.isUnipatrimoine}
                                                                  className={values.byPAV === true ? "toggle-button button-selected-green" : "toggle-button"}>OUI</ToggleButton>
                                                    <ToggleButton value={false}
                                                                  disabled={patrimoine?.isUnipatrimoine}
                                                                  className={values.byPAV === false ? "toggle-button button-selected-red" : "toggle-button"}>NON</ToggleButton>
                                                </ToggleButtonGroup>

                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={7}>
                                        {patrimoine?.isUnipatrimoine &&
                                            <Typography
                                                style={{color: patrimoine?.isUnipatrimoine ? 'grey' : 'inherit'}}>
                                                Impossible de sélectionner par PAV pour un unipatrimoine
                                            </Typography>
                                        }
                                    </Grid>
                                </Grid>
                            </>
                        }


                        {/* CONTENEUR FIELD */}
                        {values.byPAV &&
                            <Grid item container alignItems={"center"} className={"mt-2"}>
                                <Grid item xs={3}>
                                    Sélection du PAV <span className="asterix">*</span>
                                </Grid>
                                <Grid item xs={9}>
                                    <Field
                                        type="text"
                                        name="conteneur"
                                        value={values.conteneur}>
                                        {({field, form}) => (
                                            <FormControl fullWidth error={Boolean(errors.conteneur)}>
                                                <InputLabel id="label-conteneur">
                                                    Sélectionner le PAV :
                                                </InputLabel>
                                                <Select
                                                    labelId="label-conteneur"
                                                    label="Sélectionner le PAV :"
                                                    variant="outlined"
                                                    name="conteneur"
                                                    value={values.conteneur}
                                                    onChange={handleChange}
                                                >
                                                    {sortedConteneurs().map((p) => {
                                                        return (
                                                            <MenuItem key={p['@id']} value={p.id}>
                                                                {p.nomenclature} - {p.adresse}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                                {form.errors.conteneur &&
                                                    form.touched.conteneur &&
                                                    String(form.errors.conteneur) &&
                                                    <FormHelperText
                                                        error={true}>{form.errors.conteneur}</FormHelperText>}
                                            </FormControl>
                                        )
                                        }
                                    </Field>
                                </Grid>
                            </Grid>
                        }

                        {/* DATE DEBUT FIELD */}
                        <Grid item container alignItems={"center"} className={'mt-2'}>
                            <Grid item xs={3}>
                                Date de début <span className="asterix">*</span>
                            </Grid>
                            <Grid item xs={9}>
                                <Field
                                    type="text"
                                    name={"dateDebut"}
                                    value={values.dateDebut}>
                                    {({field, form}) => (
                                        <FormControl fullWidth
                                                     errors={errors.dateDebut}>
                                            <DatePicker value={parseISO(values.dateDebut)}
                                                        onChange={(value) => {
                                                            form.setFieldValue(field.name, format(value, 'yyyy-MM-dd'));
                                                        }}/>
                                        </FormControl>
                                    )}
                                </Field>
                            </Grid>
                        </Grid>


                        {/*DATE FIN FIELD*/}
                        <Grid item container alignItems={"center"} className={'mt-2'}>
                            <Grid item xs={3}>
                                Date de fin <span className="asterix">*</span>
                            </Grid>
                            <Grid item xs={9}>
                                <Field
                                    type="text"
                                    name={"dateFin"}
                                    value={values.dateFin}>
                                    {({field, form}) => (
                                        <FormControl fullWidth
                                                     errors={errors.dateFin}>
                                            <DatePicker value={parseISO(values.dateFin)}
                                                        onChange={(value) => {
                                                            form.setFieldValue(field.name, format(value, 'yyyy-MM-dd'));
                                                        }}
                                                        minDate={parseISO(values.dateDebut)}/>
                                        </FormControl>
                                    )}
                                </Field>
                            </Grid>
                        </Grid>

                        {/*Export type FIELD*/}
                        <Grid item container alignItems={"center"} className={"mt-2"}>
                            <Grid item xs={3}>
                                Format de l'export
                            </Grid>
                            <Grid item xs={9}>
                                <Field
                                    type="text"
                                    value={values.format ? values.format : false}>
                                    {({field, form}) => (
                                        <ToggleButtonGroup
                                            color={"primary"}
                                            exclusive
                                            size={"medium"}
                                            name="format"
                                            value={values.format}
                                            onChange={(event, newValue) => {
                                                if (newValue !== null) form.setFieldValue('format', newValue);
                                            }}
                                            aria-label={"Format"}
                                        >
                                            <ToggleButton value={"CSV"}
                                                          className={values.format === "CSV" ? "toggle-button button-selected-green" : "toggle-button"}>CSV</ToggleButton>
                                            <ToggleButton value={"PDF"} disabled={values.bilanType == Constants.BILAN_TYPE_GLOBAL}
                                                          className={values.format === "PDF" ? "toggle-button button-selected-red" : "toggle-button"}>PDF</ToggleButton>
                                        </ToggleButtonGroup>

                                    )}
                                </Field>
                            </Grid>
                        </Grid>

                        {/*/!* TOURNEE FIELD *!/*/}
                        {/*<Grid item container alignItems={"center"} className={"mt-2"}>*/}
                        {/*    <Grid item xs={3}>*/}
                        {/*        Sélection de la tournée <span className="asterix">*</span>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={9}>*/}
                        {/*        <Field*/}
                        {/*            type="text"*/}
                        {/*            name="campagneLavage"*/}
                        {/*            value={values.campagneLavage}>*/}
                        {/*            {({field, form}) => (*/}
                        {/*                <FormControl fullWidth error={Boolean(errors.campagneLavage)}>*/}
                        {/*                    <InputLabel id="label-campagneLavage">*/}
                        {/*                        Sélectionner la tournée :*/}
                        {/*                    </InputLabel>*/}
                        {/*                    <Select*/}
                        {/*                        labelId="label-campagneLavage"*/}
                        {/*                        label="Sélectionner la tournée :"*/}
                        {/*                        variant="outlined"*/}
                        {/*                        name="campagneLavage"*/}
                        {/*                        value={values.campagneLavage}*/}
                        {/*                        onChange={handleChange}*/}
                        {/*                    >*/}
                        {/*                        {filteredTournees() && filteredTournees().map((t) => {*/}
                        {/*                            return (*/}
                        {/*                                <MenuItem key={t['@id']} value={t.id}>*/}
                        {/*                                    {t.libelle}*/}
                        {/*                                </MenuItem>*/}
                        {/*                            )*/}
                        {/*                        })}*/}
                        {/*                    </Select>*/}
                        {/*                    {form.errors.campagneLavage &&*/}
                        {/*                        form.touched.campagneLavage &&*/}
                        {/*                        String(form.errors.campagneLavage) &&*/}
                        {/*                        <FormHelperText*/}
                        {/*                            error={true}>{form.errors.campagneLavage}</FormHelperText>}*/}
                        {/*                </FormControl>*/}
                        {/*            )*/}
                        {/*            }*/}
                        {/*        </Field>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}


                        {/*/!* OCCURENCE FIELD *!/*/}
                        {/*<Grid item container alignItems={"center"} className={"mt-2"}>*/}
                        {/*    <Grid item xs={3}>*/}
                        {/*        Sélection de la date de tournée <span className="asterix">*</span>*/}
                        {/*    </Grid>*/}
                        {/*    <Grid item xs={9}>*/}
                        {/*        <Field*/}
                        {/*            type="text"*/}
                        {/*            name="occurence"*/}
                        {/*            value={values.occurence}>*/}
                        {/*            {({field, form}) => (*/}
                        {/*                <FormControl fullWidth error={Boolean(errors.occurence)}>*/}
                        {/*                    <InputLabel id="label-campagneLavage">*/}
                        {/*                        Sélectionner la tournée :*/}
                        {/*                    </InputLabel>*/}
                        {/*                    <Select*/}
                        {/*                        labelId="label-campagneLavage"*/}
                        {/*                        label="Sélectionner la tournée :"*/}
                        {/*                        variant="outlined"*/}
                        {/*                        name="occurence"*/}
                        {/*                        value={values.occurence}*/}
                        {/*                        onChange={handleChange}*/}
                        {/*                    >*/}
                        {/*                        {filteredTourneeOccurences() && filteredTourneeOccurences().map((o, i) => {*/}
                        {/*                            return (*/}
                        {/*                                <MenuItem key={o + '-' + i} value={o}>*/}
                        {/*                                    Tournée du {formatDateToLongDate(o)}*/}
                        {/*                                </MenuItem>*/}
                        {/*                            )*/}
                        {/*                        })}*/}
                        {/*                    </Select>*/}
                        {/*                    {form.errors.occurence &&*/}
                        {/*                        form.touched.occurence &&*/}
                        {/*                        String(form.errors.occurence) &&*/}
                        {/*                        <FormHelperText*/}
                        {/*                            error={true}>{form.errors.occurence}</FormHelperText>}*/}
                        {/*                </FormControl>*/}
                        {/*            )*/}
                        {/*            }*/}
                        {/*        </Field>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}


export default SyntheseFilter;