import React, {useState} from 'react';
import {Button, Chip, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow,} from "@mui/material";
import TableHeader from "../layout/table/TableHeader";
import NoDataLabel from "../layout/NoDataLabel";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ModalDelete from "../layout/ModalDelete";
import {useNavigate} from "react-router-dom";
import Constants from "../../../utils/constants";

function PrestationsTable({prestations, ...props}) {
    const navigate = useNavigate()
    const [label, setLabel] = useState("")
    const [objectToDelete, setObjectToDelete] = useState(null)
    const [open, setOpen] = useState(false);
    const [currentPrestation, setCurrentPrestation] = useState(null);
    let currentType = null;

    // Table head cells
    const headCells = [
        {id: 'libelle', label: 'Libellé'},
    ];

    console.log(prestations)

    const openDeleteConfirmModal = (p) => {
        setCurrentPrestation(p)
        setLabel("Etes vous sûr de vouloir supprimer la prestation : ")
        setObjectToDelete(p.libelle)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentPrestation(null)
    };

    const handleEditClick = (id) => {
        navigate(Constants.APP_ROUTE_ADMIN_PRESTATIONS_EDIT.replace(':id', id))
    }

    const handleDelete = () => {
        props.onDelete(currentPrestation)
        handleClose()
    }

    return (
        <React.Fragment>
            <TableContainer component={Paper} className={"mt-2"}>
                <Table aria-label="problems table" size="small">
                    <TableHeader headCells={headCells}/>
                    <TableBody>
                        {prestations && prestations.length === 0 &&
                            <TableRow>
                                <TableCell colSpan={4} align={"center"}>
                                    <NoDataLabel label={"Il n'y a encore aucune prestation enregistrée"}/>
                                </TableCell>
                            </TableRow>
                        }
                        {prestations && prestations.map((prestations) => {
                            return (
                                <React.Fragment key={prestations.id}>
                                    <TableRow hover
                                              onClick={() => handleEditClick(prestations.id)}
                                              style={{cursor: 'pointer'}}>
                                        <TableCell>
                                            {prestations.libelle}
                                        </TableCell>
                                        <TableCell>
                                            <Grid container justify={"flex-end"} spacing={2}>
                                                <Grid item>
                                                    <Button color="secondary" size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleEditClick(prestations.id)
                                                            }}
                                                            startIcon={<ModeEditOutlineOutlinedIcon/>}>
                                                        Editer
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button color="error" size="small"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                openDeleteConfirmModal(prestations)
                                                            }}
                                                            startIcon={<CloseOutlinedIcon/>}>
                                                        Supprimer
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <ModalDelete label={label} onClose={handleClose} open={open} objectToDelete={objectToDelete}
                         selectedValue={currentPrestation} onDelete={handleDelete}/>
        </React.Fragment>
    )
}

export default PrestationsTable;
