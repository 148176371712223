import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {helper} from "../../../utils/helper";
import {dashboardActions} from "../../../modules/dashboard/dashboard.actions";
import {
    Chip,
    Paper, Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import TableHeader from "../layout/table/TableHeader";
import moment from "moment-timezone";
import Constants from "../../../utils/constants";
import Loader from "../layout/loading/loader";
import DashboardLoader from "./DashboardLoader";
import BadgeTypeLavage from "../layout/BadgeTypeLavage.js";

function DashboardTable({data, loading, ...props}) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('tournee');
    const [dashboardData, setDashboardData] = React.useState(data);

    useEffect(() => {
        setDashboardData(data)
    }, [data])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);

        // Crée une copie du tableau avant de le trier
        const sortedData = [...(data?.tournees || [])].sort((a, b) => {
            if (a[property] < b[property]) {
                return isAsc ? -1 : 1;
            } else if (a[property] > b[property]) {
                return isAsc ? 1 : -1;
            } else {
                return 0;
            }
        });

        // Crée un nouvel objet pour l'état avec les tournees triées pour forcer le re-render
        setDashboardData({...data, tournees: sortedData});
    };

    const headCells = [
        // {id: 'client', label: 'Client'},
        {id: 'libelle', label: 'Tournée'},
        {id: 'type', label: 'Type'},
        {id: 'prestation', label: 'Prestation'},
        {id: 'operateur', label: 'Opérateur'},
        {id: 'ignitionOn', label: 'Démarrage moteur'},
        {id: 'dateDebut', label: 'Première intervention'},
        {id: 'dateFin', label: 'Dernière intervention'},
        {id: 'ignitionOff', label: 'Arrêt moteur'},
        {id: 'duree', label: 'Durée'},
        {id: 'nombreLaves', label: 'Nombre lavés'},
        {id: 'nombreLavageImpossible', label: 'Nombre lavage impossible'},
        {id: 'total', label: 'Total prévu'},
        {id: 'progress', label: 'Avancement'},
        {id: 'productivite', label: "Bac à l'heure"},
    ];

    return (
        <TableContainer component={Paper} className={"mt-1"}>
            <Table aria-label="patrimoine table" size="small">
                <TableHeader headCells={headCells} noAction={true} order={order} orderBy={orderBy}
                             onRequestSort={handleRequestSort}/>
                <TableBody>
                    {loading &&
                        <DashboardLoader/>
                    }
                    {!loading && dashboardData && dashboardData.tournees && dashboardData.tournees.map(tournee => {
                        let dateDebut = helper.getFormattedTime(tournee.dateDebut);
                        let dateFin = helper.getFormattedTime(tournee.dateFin);
                        let ignitionOn = helper.getFormattedTime(tournee.ignitionOn, false)
                        let ignitionOff = helper.getFormattedTime(tournee.ignitionOff, false)
                        return (
                            <TableRow key={tournee.id}>
                                {/*<TableCell>{tournee.client}</TableCell>*/}
                                <TableCell>{tournee.libelle}</TableCell>
                                <TableCell>
                                    <BadgeTypeLavage type={tournee.type}/>
                                </TableCell>
                                <TableCell>{tournee.prestation}</TableCell>
                                <TableCell>{tournee.operateur}</TableCell>
                                <TableCell>{ignitionOn ?? "Pas démarré"}</TableCell>
                                <TableCell>{dateDebut ?? "Pas démarré"}</TableCell>
                                <TableCell>{dateFin}</TableCell>
                                <TableCell>{ignitionOff}</TableCell>
                                <TableCell>{tournee.duree}</TableCell>
                                <TableCell>{tournee.nombreLaves}</TableCell>
                                <TableCell>{tournee.nombreLavageImpossible}</TableCell>
                                <TableCell>{tournee.total}</TableCell>
                                <TableCell>{tournee.progress}%</TableCell>
                                <TableCell>{tournee.productivite.toFixed(1)}</TableCell>
                            </TableRow>
                        )
                    })}

                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default DashboardTable;