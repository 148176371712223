import React, {useEffect} from 'react';
import {Container} from "@mui/material";
import {connect} from "react-redux";
import Loader from "./layout/loading/loader";
import ButtonCreate from "./layout/ButtonCreate";
import Constants from "../../utils/constants";
import {problemsActions} from "../../modules/problems/problems.actions";
import ProblemsTable from "./problems/ProblemsTable";
import {Engineering} from "@mui/icons-material";
import PageTitle from "./layout/PageTitle";
import {prestationsActions} from "../../modules/prestations/prestations.actions.js";
import PrestationsTable from "./prestations/PrestationsTable.js";

function PrestationsPage({dispatch, ...props}) {

    useEffect(() => {
        if (!props.prestations && !props.loading && !props.error) {
            dispatch(prestationsActions.fetchPrestations())
        }
    }, [dispatch, props.prestations]);


    const handleDelete = (p) => {
        dispatch(prestationsActions.deletePrestation(p.id));
    }

    return (
        <>
            <PageTitle title={"Gestion des prestations"} icon={<Engineering/>}/>
            <ButtonCreate route={Constants.APP_ROUTE_ADMIN_PRESTATIONS_NEW} label="Ajouter une prestation"/>
            {props.loading &&
                <Loader />
            }
            {!props.loading &&
                <PrestationsTable prestations={props.prestations} onDelete={handleDelete}/>
            }
        </>
    )
}

const mapStateToProps = function (state) {
    return {
        prestations: state.prestations.prestations,
        loading: state.prestations.loading,
        error: state.prestations.error
    }
}

export default connect(mapStateToProps)(PrestationsPage);
