import React, {useEffect} from 'react';
import {connect} from "react-redux";
import Loader from "./layout/loading/loader";
import AodIcon from '@mui/icons-material/Aod';
import PageTitle from "./layout/PageTitle";
import {v4 as uuidv4} from 'uuid';

import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Chip,
    FormControl, FormHelperText,
    Grid,
    InputLabel, MenuItem,
    Select,
    Typography
} from "@mui/material";
import {interventionsActions} from "../../modules/interventions/interventions.actions";
import {patrimoinesActions} from "../../modules/patrimoines/patrimoines.actions";
import {helper} from "../../utils/helper";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {campaignsActions} from "../../modules/campaigns/campaigns.actions";
import {Field, Formik} from "formik";
import {usersActions} from "../../modules/users/users.actions";
import SubmitButton from "./layout/forms/SubmitButton";
import {DatePicker, DateTimePicker} from "@mui/x-date-pickers";
import {format, parseISO} from "date-fns";

function InterventionsFormPage({dispatch, ...props}) {
    const [selectedTournee, setSelectedTournee] = React.useState(null)
    const [selectedConteneur, setSelectedConteneur] = React.useState(null)
    const [initialLoading, setInitialLoading] = React.useState(true);
    const [campaignLoading, setCampaignLoading] = React.useState(false);


    useEffect(() => {
        if (!props.campaigns && !props.error) {
            dispatch(campaignsActions.fetchCampaigns());
        } else if (props.campaigns) {
            setInitialLoading(false)
        }
    }, [dispatch, props.campaigns, props.error]);
    useEffect(() => {
        if (!props.users && !props.loading && !props.error) {
            dispatch(usersActions.fetchUsers());
        }
    }, [dispatch, props.users, props.error]);

    useEffect(() => {
        if (selectedTournee && !props.campaign) {
            dispatch(campaignsActions.fetchCampaign(selectedTournee.id));
        }
    }, [dispatch, selectedTournee]);

    const initialValues = {
        campagne: null,
        conteneur: null,
        dateEvenement: new Date(),
        operateur: null,
    }

    const handleTourneeSelected = (tourneeId) => {
        let tournee = props.campaigns.find((item) => item["@id"] === tourneeId)
        setSelectedTournee(tournee);
    }

    const handleConteneurSelected = (cId) => {
        let conteneur = props.campaign.conteneurs.find((item) => item["@id"] === cId)
        setSelectedConteneur(conteneur);
    }

    const sortedConteneurs = () => {
        const sortedConteneurs = props.campaign && props.campaign.conteneurs
            ? [...props.campaign.conteneurs].sort((a, b) => new Date(a.lastInterventionDate) - new Date(b.lastInterventionDate))
            : [];

        return sortedConteneurs;
    }

    const getOperateurs = () => {
        if (props.users) {
            // Une tournée se créer en tant qu'exploitant.
            // Ici nous avons donc l'exploitant connecté dans props.users
            return props.users[0]?.operateurs
        }
        return []
    }

    const formatDate = (date) => {
        let newDate = parseISO(date)
        newDate.setSeconds(Math.floor(Math.random() * 60));
        return format(newDate, 'yyyy-MM-dd HH:mm:ss');
    };

    const handleSubmit = (values) => {
        values.uuid = uuidv4();
        values.adresse = selectedConteneur.adresse;
        values.volume = "" + selectedConteneur.volume;
        values.waste = selectedConteneur.dechetType;
        values.isAccessible = true;
        values.scanData = null;
        values.nombreLaves = selectedConteneur.quantite;
        values.observation = null;
        values.problemes = [];
        values.photoAvant = [];
        values.photoApres = [];
        values.photoProblem = [];
        values.isScanReadable = true;
        values.dateEvenement = formatDate(values.dateEvenement)
        dispatch(interventionsActions.createIntervention(values));
    }


    return (
        <React.Fragment>
            <PageTitle title={"Créer une intervention"} icon={<AodIcon/>}/>

            {initialLoading &&
                <Loader/>
            }
            {!initialLoading &&
                <>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={false}
                        onSubmit={(values, {setSubmitting}) => {
                            setSubmitting(true)
                            setTimeout(() => {
                                setSubmitting(false)
                            }, 1500)
                            handleSubmit(values)
                        }}
                    >
                        {({
                              values,
                              errors,
                              touched,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                              isSubmitting,
                              /* and other goodies */
                          }) => (
                            <form onSubmit={handleSubmit} className={"mb-2"}>

                                {/*TOURNEE FIELD*/}
                                <Grid item container alignItems={"center"} className={"mt-2"}>
                                    <Grid item xs={3}>
                                        Tournée
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Field
                                            type="text"
                                            name="campagne"
                                            value={values.campaignId}>
                                            {({field, form}) => (
                                                <FormControl fullWidth>
                                                    <InputLabel id="label-patrimoine">Sélectionner la tournée
                                                        :</InputLabel>
                                                    <Select
                                                        labelId="label-patrimoine"
                                                        label="Sélectionner la tournée :"
                                                        variant="outlined"
                                                        name="campagne"
                                                        value={values.campaignId}
                                                        onChange={(e) => {
                                                            handleTourneeSelected(e.target.value, form)
                                                            handleChange(e)
                                                        }}
                                                    >
                                                        {props.campaigns && props.campaigns.map((tournee) => {
                                                            return (
                                                                <MenuItem key={tournee.id} value={tournee["@id"]}>
                                                                    {tournee.libelle} - {(tournee.nombrePAV) + " points"} / {tournee.nombreBacs + " bacs"} - {tournee.operateurs[0].libelle}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>

                                {/*CONTENEUR FIELD*/}
                                <Grid item container alignItems={"center"} className={"mt-2"}>
                                    <Grid item xs={3}>
                                        Selection du conteneur
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Field
                                            type="text"
                                            name="conteneur"
                                            value={values.conteneurId}>
                                            {({field, form}) => (
                                                <FormControl fullWidth>
                                                    <InputLabel id="label-patrimoine">Sélectionner le conteneur
                                                        :</InputLabel>
                                                    <Select
                                                        labelId="label-conteneur"
                                                        label="Sélectionner le conteneur :"
                                                        variant="outlined"
                                                        name="conteneur"
                                                        value={values.conteneurId}
                                                        onChange={(e) => {
                                                            handleConteneurSelected(e.target.value, form)
                                                            handleChange(e)
                                                        }}
                                                    >
                                                        {sortedConteneurs() && sortedConteneurs().map((c) => {
                                                            return (
                                                                <MenuItem key={c.id} value={c["@id"]}>
                                                                    {c.nomenclature} - {helper.formatDate(c.lastInterventionDate)}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            )
                                            }
                                        </Field>
                                    </Grid>
                                </Grid>

                                {/* OPERATEURS FIELD*/}
                                <Grid item container alignItems={"center"} className={"mt-2"}>
                                    <Grid item xs={3}>
                                        <Typography>Sélectionner les opérateurs *</Typography>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Field
                                            type="text"
                                            name={"operateur"}
                                            value={values.operateur}>
                                            {({form, push, remove}) => (
                                                <FormControl fullWidth>
                                                    <InputLabel id="label-client">Sélectionner les opérateurs
                                                        :</InputLabel>
                                                    <Select
                                                        labelId="label-client"
                                                        label="Sélectionner les opérateurs :"
                                                        variant="outlined"
                                                        name="operateur"
                                                        value={values.operateur}
                                                        onChange={(e) => {
                                                            form.setFieldValue(
                                                                "operateur",
                                                                e.target.value
                                                            );
                                                        }}
                                                    >
                                                        {getOperateurs() && getOperateurs().map((o) => {
                                                            return (
                                                                <MenuItem key={o.id} value={o["@id"]}>
                                                                    {o.libelle ?? o.email}
                                                                </MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                    {errors.client &&
                                                        String(errors.client) &&
                                                        <FormHelperText error={true}>{errors.client}</FormHelperText>}
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>

                                {/*DATE EVENT FIELD*/}
                                <Grid item container alignItems={"center"} className={'mt-2 mb-1'}>
                                    <Grid item xs={3}>
                                        Début d'intervention <span className="asterix">*</span>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Field
                                            type="text"
                                            name={"dateEvenement"}
                                            value={values.dateEvenement}>
                                            {({field, form}) => (
                                                <FormControl fullWidth
                                                >
                                                    <DateTimePicker value={parseISO(values.dateEvenement)}
                                                                    onChange={(value) => {
                                                                        form.setFieldValue(field.name, format(value, 'yyyy-MM-dd HH:mm:ss'));
                                                                    }}
                                                    />
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Grid>
                                </Grid>

                                <SubmitButton submitting={isSubmitting} label={"Enregistrer l'intervention"}
                                              color={"secondary"}/>
                            </form>
                        )}
                    </Formik>
                </>

            }
        </React.Fragment>
    )
}

const mapStateToProps = function (state) {
    return {
        campaigns: state.campaigns.campaigns,
        campaign: state.campaigns.campaign,
        users: state.users.users,
        loading: state.campaigns.loading || state.users.loading,
        error: state.campaigns.error || state.users.error,
    }
}

export default connect(mapStateToProps)(InterventionsFormPage);
