import React, {useRef, useEffect, useCallback, useState} from 'react';
import {createRoot} from 'react-dom/client';
import {useMap} from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {Fullscreen} from '@styled-icons/bootstrap/Fullscreen';
import {FullscreenExit} from '@styled-icons/bootstrap/FullscreenExit';


const FullscreenControl = () => {
    const map = useMap();
    const buttonRef = useRef(null);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const checkFullscreen = useCallback(() => {
        return !!(
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement
        );
    }, []);

    const toggleFullscreen = useCallback(() => {
        const container = map.getContainer();

        if (!checkFullscreen()) {
            if (container.requestFullscreen) {
                container.requestFullscreen();
            } else if (container.mozRequestFullScreen) {
                container.mozRequestFullScreen();
            } else if (container.webkitRequestFullscreen) {
                container.webkitRequestFullscreen();
            } else if (container.msRequestFullscreen) {
                container.msRequestFullscreen();
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        }
    }, [map, checkFullscreen]);

    useEffect(() => {
        const onFullscreenChange = () => {
            const fullscreenState = checkFullscreen();
            setIsFullscreen(fullscreenState);
            console.log("Fullscreen state changed:", fullscreenState);
        };

        document.addEventListener('fullscreenchange', onFullscreenChange);
        document.addEventListener('webkitfullscreenchange', onFullscreenChange);
        document.addEventListener('mozfullscreenchange', onFullscreenChange);
        document.addEventListener('MSFullscreenChange', onFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', onFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', onFullscreenChange);
            document.removeEventListener('mozfullscreenchange', onFullscreenChange);
            document.removeEventListener('MSFullscreenChange', onFullscreenChange);
        };
    }, [checkFullscreen]);

    const updateButton = useCallback(() => {
        if (buttonRef.current) {
            const root = createRoot(buttonRef.current);
            root.render(isFullscreen ? <FullscreenExit/> : <Fullscreen size={20}/>);
            buttonRef.current.title = isFullscreen ? 'Quitter le plein écran' : 'Plein écran';
        }
    }, [isFullscreen]);

    useEffect(() => {
        updateButton();
    }, [isFullscreen, updateButton]);

    React.useEffect(() => {
        const fullscreenControl = L.Control.extend({
            options: {
                position: 'topright'
            },
            onAdd: function () {
                const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control');
                const button = L.DomUtil.create('a', 'fullscreen-button', container);

                // Pour afficher l'icone du bouton on est obligé d'interpreter le React via react-root
                const root = createRoot(button);
                root.render(checkFullscreen() ? <FullscreenExit/> : <Fullscreen size={20}/>);

                button.title = 'Plein écran';

                L.DomEvent.on(button, 'click', (e) => {
                    L.DomEvent.stopPropagation(e);
                    L.DomEvent.preventDefault(e);
                    toggleFullscreen();
                });

                return container;
            }
        });

        map.addControl(new fullscreenControl());

        // return () => {
        //     map.removeControl(fullscreenControl);
        // };
    }, [map, toggleFullscreen, checkFullscreen]);

    return null;
};

export default FullscreenControl;