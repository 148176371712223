import {prestationsConstants} from "./prestations.constants.js";
import {prestationsService} from "./prestations.service.js";
import {toastHelper} from "../../utils/toast-helper";
import Constants from "../../utils/constants";
import history from "../../utils/history";

export const prestationsActions = {
    fetchPrestations,
    fetchPrestation,
    deletePrestation,
    createPrestation,
    updatePrestation,
    clearPrestations
};

function fetchPrestations(pagination = false) {
    return dispatch => {
        dispatch(request());

        prestationsService.fetchPrestations(pagination)
            .then(
                prestations => {
                    let res = prestations;
                    try {
                        res = prestations.sort((a, b) => a.lastname.localeCompare(b.lastname));
                    } catch (e) {

                    }
                    dispatch(success(res));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: prestationsConstants.PRESTATIONS_FETCH_REQUEST}
    }

    function success(prestations) {
        return {type: prestationsConstants.PRESTATIONS_FETCH_SUCCESS, prestations}
    }

    function failure(error) {
        return {type: prestationsConstants.PRESTATIONS_FETCH_FAILURE, error}
    }
}

function fetchPrestation(id) {
    return dispatch => {
        dispatch(request());
        prestationsService.fetchPrestation(id)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: prestationsConstants.PRESTATIONS_FETCH_ONE_REQUEST}
    }

    function success(prestation) {
        return {type: prestationsConstants.PRESTATIONS_FETCH_ONE_SUCCESS, prestation}
    }

    function failure(error) {
        return {type: prestationsConstants.PRESTATIONS_FETCH_ONE_FAILURE, error}
    }
}

function deletePrestation(id, navigate) {
    return dispatch => {
        dispatch(request());

        prestationsService.deletePrestation(id)
            .then(
                id => {
                    dispatch(success(id));
                    history.replace(Constants.APP_ROUTE_ADMIN_PRESTATIONS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };


    function request() {
        return {type: prestationsConstants.PRESTATIONS_DELETE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("La prestation a bien été supprimée.")
        return {type: prestationsConstants.PRESTATIONS_DELETE_SUCCESS, id}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: prestationsConstants.PRESTATIONS_DELETE_FAILURE, error}
    }
}

function createPrestation(values, navigate) {
    return dispatch => {
        dispatch(request());

        prestationsService.createPrestation(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_PRESTATIONS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: prestationsConstants.PRESTATIONS_CREATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("La prestation a bien été créé.")
        return {type: prestationsConstants.PRESTATIONS_CREATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: prestationsConstants.PRESTATIONS_CREATE_FAILURE, error}
    }
}

function updatePrestation(values, navigate) {
    return dispatch => {
        dispatch(request());
        prestationsService.updatePrestation(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_PRESTATIONS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: prestationsConstants.PRESTATIONS_UPDATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("La prestation a bien été modifié.")
        return {type: prestationsConstants.PRESTATIONS_UPDATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: prestationsConstants.PRESTATIONS_UPDATE_FAILURE, error}
    }
}

function clearPrestations() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {type: prestationsConstants.PRESTATIONS_CLEAR_REQUEST}
    }
}
