import React, {useEffect, useMemo, useRef} from "react";
import L from "leaflet";
import {useMap} from "react-leaflet";
import localforage from "localforage";
import Constants from "../../../../utils/constants";
import {debounce} from "@mui/material";

const CustomTileLayer = React.memo(({ url = Constants.MAP_TILE_OSM, ...props }) => {
    const map = useMap();
    const tileLayerRef = useRef(null);

    const tileLayerOptions = useMemo(() => ({
        ...props,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }), [props]);

    useEffect(() => {
        if (!tileLayerRef.current) {
            tileLayerRef.current = L.tileLayer(url, tileLayerOptions);
            tileLayerRef.current.getTileUrl = function (coords) {
                return this._url
                    .replace('{z}', coords.z)
                    .replace('{x}', coords.x)
                    .replace('{y}', coords.y);
            };
            tileLayerRef.current.addTo(map);
        }

        const handleZoomEnd = debounce(() => {
            // Ajoutez ici la logique nécessaire après un zoom
        }, 400);

        map.on('zoomend', handleZoomEnd);

        return () => {
            map.off('zoomend', handleZoomEnd);
            if (tileLayerRef.current) {
                map.removeLayer(tileLayerRef.current);
                tileLayerRef.current = null;
            }
        };
    }, [map, url, tileLayerOptions]);

    return null;
});

export default CustomTileLayer;
