import {campaignsConstants} from "./campaigns.constants";
import {campaignsService} from "./campaigns.service";
import {toastHelper} from "../../utils/toast-helper";
import Constants from "../../utils/constants";
import history from "../../utils/history";

export const campaignsActions = {
    fetchCampaigns,
    fetchCampaign,
    deleteCampaign,
    createCampaign,
    updateCampaign,
    clearCampaigns
};

function fetchCampaigns(pagination = false) {
    return dispatch => {
        dispatch(request());

        campaignsService.fetchCampaigns(pagination)
            .then(
                campaigns => {
                    dispatch(success(campaigns));
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: campaignsConstants.CAMPAIGNS_FETCH_REQUEST}
    }

    function success(campaigns) {
        return {type: campaignsConstants.CAMPAIGNS_FETCH_SUCCESS, campaigns}
    }

    function failure(error) {
        return {type: campaignsConstants.CAMPAIGNS_FETCH_FAILURE, error}
    }
}

function fetchCampaign(id, callback = null) {
    return dispatch => {
        dispatch(request());
        campaignsService.fetchCampaign(id)
            .then(
                campaign => {
                    dispatch(success(campaign));
                    if (callback) {
                        callback(campaign)
                    }
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: campaignsConstants.CAMPAIGNS_FETCH_ONE_REQUEST}
    }

    function success(campaign) {
        return {type: campaignsConstants.CAMPAIGNS_FETCH_ONE_SUCCESS, campaign}
    }

    function failure(error) {
        return {type: campaignsConstants.CAMPAIGNS_FETCH_ONE_FAILURE, error}
    }
}

function deleteCampaign(id, navigate) {
    return dispatch => {
        dispatch(request());

        campaignsService.deleteCampaign(id)
            .then(
                id => {
                    dispatch(success(id));
                    history.replace(Constants.APP_ROUTE_ADMIN_CAMPAIGNS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };


    function request() {
        return {type: campaignsConstants.CAMPAIGNS_DELETE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("La tournée a bien été supprimée.")
        return {type: campaignsConstants.CAMPAIGNS_DELETE_SUCCESS, id}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: campaignsConstants.CAMPAIGNS_DELETE_FAILURE, error}
    }
}

function createCampaign(values, navigate) {
    return dispatch => {
        dispatch(request());

        campaignsService.createCampaign(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_CAMPAIGNS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: campaignsConstants.CAMPAIGNS_CREATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("La tournée a bien été créée.")
        return {type: campaignsConstants.CAMPAIGNS_CREATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: campaignsConstants.CAMPAIGNS_CREATE_FAILURE, error}
    }
}

function updateCampaign(values, navigate) {
    return dispatch => {
        dispatch(request());
        campaignsService.updateCampaign(values)
            .then(
                () => {
                    dispatch(success());
                    history.replace(Constants.APP_ROUTE_ADMIN_CAMPAIGNS);
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() {
        return {type: campaignsConstants.CAMPAIGNS_UPDATE_REQUEST}
    }

    function success(id) {
        toastHelper.displaySuccess("La tournée a bien été modifié.")
        return {type: campaignsConstants.CAMPAIGNS_UPDATE_SUCCESS}
    }

    function failure(error) {
        toastHelper.handleError(error, "Oops, une erreur est survenue.")
        return {type: campaignsConstants.CAMPAIGNS_UPDATE_FAILURE, error}
    }
}

function clearCampaigns() {
    return dispatch => {
        dispatch(request());
    };

    function request() {
        return {type: campaignsConstants.CAMPAIGNS_CLEAR_REQUEST}
    }
}
