import Constants from "./constants";
import markerVert from '../assets/images/marker-vert.png';
import markerOrange from '../assets/images/marker-orange.png';
import markerRouge from '../assets/images/marker-rouge.png';
import markerGris from '../assets/images/marker-gris.png';
import {format} from "date-fns";
import moment from "moment-timezone";


const conteneursStatus = [markerVert, markerGris, markerOrange, markerRouge];
export const helper = {
    /**
     * Parse jwt returned by server
     * @param token
     * @returns {any|null}
     */
    parseJwt: (token) => {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const decodedData = decodeURIComponent(atob(base64).split('').map((c) => {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            return JSON.parse(decodedData);
        } catch (e) {
            return null;
        }
    },

    /**
     * Parse data from jwt and store it
     * @param user
     */
    storeJwt: (user) => {
        let data = helper.parseJwt(user.token)
        localStorage.setItem('token', JSON.stringify(user.token));
        localStorage.setItem('refresh_token', JSON.stringify(user.refresh_token));
        localStorage.setItem('user', JSON.stringify(data))
        return data;
    },

    /**
     * Retrieve current logged in user
     * @returns {{}|any}
     */
    getCurrentUser: () => {
        try {
            return JSON.parse(localStorage.getItem("user"))
        } catch (e) {
            return {}
        }
    },

    calculateBbox(data) {
        if (data.length === 0) {
            return null; // Retourne null si le tableau est vide
        }

        let minLat = data[0].latitude;
        let maxLat = data[0].latitude;
        let minLng = data[0].longitude;
        let maxLng = data[0].longitude;

        for (let i = 1; i < data.length; i++) {
            const container = data[i];
            const {latitude, longitude} = container;

            if (latitude < minLat) {
                minLat = latitude;
            }
            if (latitude > maxLat) {
                maxLat = latitude;
            }
            if (longitude < minLng) {
                minLng = longitude;
            }
            if (longitude > maxLng) {
                maxLng = longitude;
            }
        }

        const southwest = new window.google.maps.LatLng(minLat, minLng);
        const northeast = new window.google.maps.LatLng(maxLat, maxLng);

        return new window.google.maps.LatLngBounds(southwest, northeast);
    },

    geocodeAddress: async (adresse) => {
        return new Promise((resolve, reject) => {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({address: adresse}, (results, status) => {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    if (results.length > 0) {
                        const {lat, lng} = results[0].geometry.location;
                        resolve({latitude: lat(), longitude: lng()});
                    } else {
                        reject(new Error('Aucun résultat de géocodage trouvé.'));
                    }
                } else {
                    reject(new Error('Échec du géocodage. Veuillez réessayer.'));
                }
            });
        });
    },

    reverseGeocode: async (lat, lng) => {
        return new Promise((resolve, reject) => {
            const geocoder = new window.google.maps.Geocoder();
            const latlng = {lat, lng};
            geocoder.geocode({location: latlng}, (results, status) => {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    if (results.length > 0) {
                        const address = results[0].address_components;
                        resolve(address);
                    } else {
                        reject(new Error('Aucun résultat de géocodage inversé trouvé.'));
                    }
                } else {
                    reject(new Error('Échec du géocodage inversé. Veuillez réessayer.'));
                }
            });
        });
    },

    extractAddressAndCommuneFromComponents: (addressComponents) => {
        let adresse = '';
        let commune = '';

        addressComponents.forEach(component => {
            const types = component.types;
            if (types.includes('street_number')) {
                adresse += component.long_name + ' ';
            }
            if (types.includes('route')) {
                adresse += component.long_name;
            }
            if (types.includes('locality')) {
                commune = component.long_name;
            }
        });

        return {adresse: adresse.trim(), commune};
    },


    normalizeString: (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^\w\s']/gi, "").replace(/\s+/g, "").replace(/[^\w\s]/gi, "").replace(/\s+/g, "");
    },

    getConteneurStatusName: (status) => {
        if (status == Constants.CONTENEURS_STATUS_DONE) {
            return "Lavé"
        } else if (status == Constants.CONTENEURS_STATUS_PROBLEME) {
            return "Problème remonté"
        } else if (status == Constants.CONTENEURS_STATUS_INACCESSIBLE) {
            return "Lavage impossible";
        } else {
            return "Pas encore lavé"
        }
    },

    getConteneurStatusIcon: (status) => {
        if (status == null || status < 0 || status > 3) {
            return conteneursStatus[1];
        }
        return conteneursStatus[status]
    },

    padZero: (number) => {
        return number < 10 ? `0${number}` : number;
    },

    formatDate: (datestr) => {
        const date = new Date(datestr);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        if (day === '01' && month === '01' && year === 1970) {
            return '';
        }

        return `${day}/${month}/${year}`;
    },

    getOccurrences: (rruleStr, startDate, endDate) => {
        const rruleParts = rruleStr.split(';');
        const rruleObj = {};
        let occurrences = [];

        rruleParts.forEach(part => {
            const [key, value] = part.split('=');
            rruleObj[key] = value;
        });

        const start = new Date(startDate);
        const end = new Date(endDate);
        const until = new Date(rruleObj['UNTIL']);
        const interval = parseInt(rruleObj['INTERVAL'], 10) || 1;

        let date = new Date(start);

        while (date <= end && date <= until) {
            const formattedDate = date.toLocaleDateString('fr-FR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
            });
            occurrences.push(formattedDate);

            if (rruleObj['FREQ'] === 'DAILY') {
                date.setDate(date.getDate() + interval);
            } else if (rruleObj['FREQ'] === 'WEEKLY') {
                date.setDate(date.getDate() + 7 * interval);
            } else if (rruleObj['FREQ'] === 'MONTHLY') {
                date.setMonth(date.getMonth() + interval);
            } else {
                // Unsupported frequency
                break;
            }
        }

        return occurrences;
    },

    getFormattedDate: (created) => {
        if (!created) return null;
        const utcDate = moment.utc(created);
        return utcDate.format("DD/MM/YYYY HH[h]mm");
    },

    getFormattedTime: (created, withTimezone = true) => {
        if (!created) return null;
        const utcDate = withTimezone ? moment.utc(created) : moment(created)
        return utcDate.format("HH") + "h" + utcDate.format("mm");
    },

    getInterventionStatus: (intervention) => {
        if (intervention) {
            if (!intervention?.isAccessible) return "Lavage impossible";
            if (intervention?.problemes?.length > 0) return "Maintenance nécessaire";
            return "Lavé";
        }
       return "Non Lavé";
    },

    getInterventionStatusColor: (intervention) => {
        if (intervention) {
            if (!intervention?.isAccessible) return {backgroundColor: "#B22222", color: "white"}
            if (intervention?.problemes?.length > 0) return {backgroundColor: "#F39A3F", color: "white"}
            return {backgroundColor: "#88bc24", color: "white"};
        }
    },

    getConteneurTypeLibelle: (type) => {
        if (type) {
            if (["CE", "CA", "CSE"].indexOf(type)) return "PAV(s)";
        }

        return "Bac(s)"
    },
    getColorForDechetType: (dechetType) => {
        if (dechetType) {
            if (dechetType === "VER") {
                return {backgroundColor: "#5aa059", color: "white"};
            }

            if (dechetType === "OM") {
                return {backgroundColor: "#666564", color: "white"};
            }
            if (dechetType === "REC") {
                return {backgroundColor: "#e9d01e", color: "black"};
            }
            if (dechetType === "PAP") {
                return {backgroundColor: "#4278c8", color: "white"};
            }
            if (dechetType === "BIO") {
                return {backgroundColor: "#ac7c4d", color: "white"};
            }
        }

        return {backgroundColor: "#4b4b4b", color: "white"};
    },
    getColorForStatus: (status) => {
        if (status == Constants.CONTENEURS_STATUS_DONE) {
            return {backgroundColor: "#88bc24", color: "white"};
        } else if (status == Constants.CONTENEURS_STATUS_PROBLEME) {
            return {backgroundColor: "#F39A3F", color: "white"}
        } else if (status == Constants.CONTENEURS_STATUS_INACCESSIBLE) {
            return {backgroundColor: "#B22222", color: "white"}
        } else {
            return {backgroundColor: "#eee", color: "black"}
        }
    },
    
    getTypeLavageColor: (type) => {
        if (type === Constants.TYPOLOGIE_LAVAGE_AB_PLUS_B) {
            return "toggle-button button-selected-primary"
        }
        if (type === Constants.TYPOLOGIE_LAVAGE_AB) {
            return "toggle-button button-selected-secondary"
        }
        if (type === Constants.TYPOLOGIE_LAVAGE_B) {
            return "toggle-button button-selected-orange"
        }
        if (type === Constants.TYPOLOGIE_LAVAGE_C) {
            return "toggle-button button-selected-gray"
        }
    }
}
