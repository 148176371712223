export const prestationsConstants = {
    PRESTATIONS_FETCH_REQUEST: "PRESTATIONS_FETCH_REQUEST",
    PRESTATIONS_FETCH_SUCCESS: "PRESTATIONS_FETCH_SUCCESS",
    PRESTATIONS_FETCH_FAILURE: "PRESTATIONS_FETCH_FAILURE",

    PRESTATIONS_FETCH_ONE_REQUEST: "PRESTATIONS_FETCH_ONE_REQUEST",
    PRESTATIONS_FETCH_ONE_SUCCESS: "PRESTATIONS_FETCH_ONE_SUCCESS",
    PRESTATIONS_FETCH_ONE_FAILURE: "PRESTATIONS_FETCH_ONE_FAILURE",

    PRESTATIONS_CREATE_REQUEST: "PRESTATIONS_CREATE_REQUEST",
    PRESTATIONS_CREATE_SUCCESS: "PRESTATIONS_CREATE_SUCCESS",
    PRESTATIONS_CREATE_FAILURE: "PRESTATIONS_CREATE_FAILURE",

    PRESTATIONS_UPDATE_REQUEST: "PRESTATIONS_UPDATE_REQUEST",
    PRESTATIONS_UPDATE_SUCCESS: "PRESTATIONS_UPDATE_SUCCESS",
    PRESTATIONS_UPDATE_FAILURE: "PRESTATIONS_UPDATE_FAILURE",

    PRESTATIONS_DELETE_REQUEST: "PRESTATIONS_DELETE_REQUEST",
    PRESTATIONS_DELETE_SUCCESS: "PRESTATIONS_DELETE_SUCCESS",
    PRESTATIONS_DELETE_FAILURE: "PRESTATIONS_DELETE_FAILURE",



    PRESTATIONS_CLEAR_REQUEST: "PRESTATIONS_CLEAR_REQUEST",

};
